<template>
    <div>
    </div>
</template>

<script>
import { mapActions,mapState } from "vuex";


export default {
    name:'alipay',
    data() {
        return {
        }
    },
    methods:{
        ...mapActions('broadBand',['showcasePay'])
    },
    async created() {
        let {...params} = this.$route.query
        // 拿到form表单 渲染
        let resAlipay = await this.showcasePay(params)
        // 存储订单编号
        localStorage.setItem('orderId',resAlipay.orderNum)
        // 构建表单
        const div = document.createElement('div');
        div.innerHTML = resAlipay.form;
        document.body.appendChild(div);
        document.forms[0].submit();
    }
}
</script>

<style>

</style>